.demo{
    background-color:  #131723;    
    height: 100vh;
    .login{    
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        .login-wrapper {
            width: 532px;
            padding: 56px 9px;
            border-radius: 6px;
            box-sizing: border-box;
            position: relative;
            border: 1px solid transparent;
            background-image: linear-gradient(26deg,#111e2c 10%,#13283b 82%);
            .login-header {
                display: flex;
                align-items: center;
                height: 48px;
                padding: 0 16px;
                span {
                    display: block;
                    width: 100%;
                    text-transform: uppercase;
                    font-family: Oxanium;
                    font-size: 24px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.17;
                    letter-spacing: normal;
                    text-align: center;
                    color: #5be5f2;
                }
            }
            .login-body {
                padding: 0 64px;
                .form__group {
                    position: relative;
                    padding: 15px 0 0;
                    margin-top: 10px;
                    width: 100%;
                }
                .form__label {
                    position: absolute;
                    top: -5px;
                    display: block;
                    font-size: 13px;
                    left: 0;
                    font-family: Oxanium;
                    font-size: 12px;
                    line-height: 1.25;
                    letter-spacing: .33px;
                    text-align: left;
                    color: #b8d0ed;
                }
               .form__field {
                    border-radius: 2px;
                    border: 0.5px solid #739fb7;
                    background-color: #0a111c;
                    font-family: Oxanium;
                    font-size: 13px;
                    line-height: 1.15;
                    letter-spacing: .36px;
                    text-align: left;
                    color: #739fb7;
                    width: 100%;
                    height: 36px;
                    box-sizing: border-box;
                    outline: 0;
                    padding-left: 8px;
                    padding-right: 8px;
                }
                .g-recaptcha {
                    margin-top: 8px;
                }
                .sign-in {
                    margin-bottom: 0;
                    margin-top: 24px;
                    .button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        border: none;
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: 600;
                        height: 48px;
                        border-radius: 2px;
                        background-image: linear-gradient(281deg,#1cbedd,#2183d6);
                        color: #fff;
                    }
                }
            }
            .error {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border: none;
                text-transform: uppercase;
                font-size: 11px;
                color: #f55252;
            }
        }
    }
}

#demoIframe{
    height: 100vh;
}